export const HEADER_IMAGES = {
  recipesPage:
    'https://static.wixstatic.com/media/f7bd72_650d5b64dadb4b59905f686016e31b1b~mv2.png',
  recipesDiscoverPage:
    'https://static.wixstatic.com/media/f7bd72_e03b3faaf463460a87f0662628574d46~mv2.jpg',
  recipesSearchPage:
    'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
  recipeVideosPage:
    'https://static.wixstatic.com/media/f7bd72_a72ef8cf752648ecbe948a4c00b7bb8b~mv2.png',
  aboutPage: '',
  contactPage: ''
};
